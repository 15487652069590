import React from "react";
import './Navbar.css'

function Navbar() {
    return (
        <nav>
            <h1>Main</h1>
            <hr></hr>
            <h1>About</h1>
            <hr></hr>
            <h1>Projects</h1>
            <hr></hr>
            <h1>Contact</h1>
        </nav>
    )
}

export default Navbar