import React from "react";
import './Main.css'
import twitterLogo from "./images/twitterlogo.png";
import linkedinLogo from "./images/linkedinlogo.png";
import githubLogo from "./images/githublogo2.png";
import mailLogo from "./images/maillogo.png";
import profilePhoto from "./images/pp.png";
//import twitterLogo from require("../images/icons/twittericon.png")

function Main() {
    return (
        <main>
            <div>
                <div>
                    <h1>Eyup Baytur</h1>
                    <p>Front-End Developer</p>
                </div>
                <div>
                    <a href="https://linkedin.com/in/eyupbaytur"><img className="social--logo" src={linkedinLogo} /></a>
                    <a href="https://github.com/eyupbaytur"><img className="social--logo" src={githubLogo} alt="linkedin logo"/></a>
                    <a href="mailto:eyupbaytur@gmail.com"><img className="social--logo" src={mailLogo} alt="gmail logo"/></a>
                </div>
            </div>
            <div>
                <img src="https://preview.redd.it/d3gdpzivovj71.jpg?width=400&format=pjpg&auto=webp&s=91f7d890558c91bc209b4911a03a1dac5d79e5b6" alt="a profile picture of me"/>
            </div>
        </main>
    )
}

export default Main