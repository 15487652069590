import React from "react";
import "./Footer.css"

function Footer() {
    return (
        <div className="footer--component">
            <hr></hr>
            <p>Made by Eyup Baytur @2022</p>
        </div>
    )
}

export default Footer