//import logo from './logo.svg';
//import './App.css';

import React from "react"
import "./App.css"
import Navbar from "./components/navbar/Navbar"
import Main from "./components/main/Main"
import About from "./components/about/About"
import Projects from "./components/projects/Projects"
import Contact from "./components/contact/Contact"
import Footer from "./components/footer/Footer"

function App() {
  return(
      <div className="app--component">
          <Navbar/>
          <Main/>
          <About/>
          <Projects/>
          <Contact/>
          <Footer/>
      </div>
  )
}

export default App